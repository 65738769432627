body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans';
    font-size: 16px;
    line-height: 1.5;
}

.page-container {
    position: relative;
    background: #eff9fa;
}

.content {
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    margin-right: 10%;
    /* width: 80%; */
    height: 100vh;
    /* justify-content: center; */
    /* align-items: center; */
}

h1 {
    text-align: center;
    justify-content: center;
    font-size: 50px;
}

button {
    margin: 5px;
}

.login-button {
    border: none;
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
}

.get-started-button {
    background-color: #913291;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
}