.columns-3 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.fp-content {
    padding: 30px
}

.columns-3 > * {
    width: 100%;
}

@media (min-width: 992px) {
    .columns-3 > * {
        width:calc(100% / 3);
     }
}

.columns-2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.columns-2 > * {
    width: 100%;
}

@media (min-width: 992px) {
    .columns-2 > * {
        width:calc(100% / 2);
     }
}

.centered {
    text-align: center;
}

.centered i {
    vertical-align: middle;
}

.get-started-button {
    background-color: #913291; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
}