.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}

.flexChild {
    flex: 1;
    padding-top: 16px;
    padding-bottom: 16px;
}

.flexChild:first-child {
    margin-left: 32px;
}

.flexChild:last-child {
    margin-right: 32px;
    display: flex;
    justify-content: flex-end;
}

.Logo {
    height: 50px;
    width: 100%;
    overflow: visible;
}

.logoContainer {
    max-width: 200px;
}

.menuContainer {
    height: 100%;
}

.burgerMenu {
    max-width: 70px;
}

@media (max-width: 960px) {
    .Logo {
        height: 30px;
    }

    .logoContainer {
        max-width: none;
        padding: 32px 0;
    }
}
